import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { Waypoint } from "react-waypoint";
import { Col, Container, Row } from "reactstrap";

import * as COLORS from "../common/colors";
import ButtonRadio from "../components/ButtonRadio";
import Chart from "../components/charts/Chart";
import FormatUtils from "../utils/FormatUtils";
import Utils from "../utils/Utils";

class TechTrendsLayout extends React.Component {
  constructor(props) {
    super(props);

    this.scroll = {};

    this.state = {
      data: TechTrendsLayout._getFirstPageData(),
      scrollTo: null
    };
  }

  _onResize = () => {
    if (
      !Utils.isNull(this.scroll) &&
      !Utils.isNull(this.state.scrollTo) &&
      !Utils.isNull(this.scroll[this.state.scrollTo])
    ) {
      // This is broken right now, as the zooming also changes the element that
      // we are trying to scroll to.
      this.scroll[this.state.scrollTo].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  };

  static _getFirstPageData() {
    return {
      title: "Internet Users vs. Y/Y Growth",
      labels: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017],
      showGrid: true,
      tooltipFunction: x =>
        x.datasetIndex === 1
          ? FormatUtils.formatInteger(x.yLabel)
          : x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.yLabel)
          : x.yLabel,
      graphs: [
        {
          type: "line",
          data: {
            2010: 12.92,
            2011: 13.14,
            2012: 10.2,
            2013: 10.82,
            2014: 9.23,
            2015: 9.58,
            2016: 11.87,
            2017: 7.12
          },
          label: "Y/Y Growth",
          ordinate: "Y/Y Growth",
          yTicks: (label, index, labels) => {
            return FormatUtils.formatPercentage(label);
          },
          yBeginAtZero: true,
          fill: false,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY,
          borderWidth: 3
        },
        {
          type: "bar",
          data: {
            2009: 1.62e9,
            2010: 1.82e9,
            2011: 2.06e9,
            2012: 2.27e9,
            2013: 2.52e9,
            2014: 2.76e9,
            2015: 3.01e9,
            2016: 3.37e9,
            2017: 3.62e9
          },
          label: "Global Internet Users",
          ordinate: "Internet Users, Global",
          // Todo: This should be part of a canonical lib.
          yTicks: (label, index, labels) => {
            return FormatUtils.formatInteger(label);
          },
          yBeginAtZero: true,
          fill: true,
          borderColor: COLORS.FRENCH_ROSE,
          fillColor: COLORS.FRENCH_ROSE
        }
      ]
    };
  }

  static _getSecondPageData() {
    return {
      title: "New Smartphone Unit Shipments vs. Y/Y Growth",
      labels: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017],
      showGrid: true,
      stacked: true,
      tooltipFunction: x =>
        x.datasetIndex > 0
          ? FormatUtils.formatInteger(x.yLabel)
          : x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.yLabel)
          : x.yLabel,
      graphs: [
        {
          type: "line",
          data: {
            2009: 17.37,
            2010: 73.16,
            2011: 61.32,
            2012: 46.84,
            2013: 40.0,
            2014: 28.42,
            2015: 10.0,
            2016: 1.84,
            2017: 0
          },
          label: "Y/Y Growth",
          ordinate: "Y/Y Growth",
          yTicks: (label, index, labels) => {
            return FormatUtils.formatPercentage(label);
          },
          yBeginAtZero: true,
          fill: false,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY,
          borderWidth: 3
        },
        {
          type: "bar",
          data: {
            2009: 0.009e9,
            2010: 0.066e9,
            2011: 0.241e9,
            2012: 0.504e9,
            2013: 0.807e9,
            2014: 1.061e9,
            2015: 1.171e9,
            2016: 1.246e9,
            2017: 1.25e9
          },
          label: "Android",
          ordinate: "New Smartphone Shipments, Global",
          // Todo: This should be part of a canonical lib.
          yTicks: label => {
            return FormatUtils.formatInteger(label);
          },
          yBeginAtZero: true,
          fill: true,
          borderColor: COLORS.FRENCH_ROSE,
          fillColor: COLORS.FRENCH_ROSE
        },
        {
          type: "bar",
          data: {
            2009: 0.031e9,
            2010: 0.053e9,
            2011: 0.096e9,
            2012: 0.14e9,
            2013: 0.158e9,
            2014: 0.202e9,
            2015: 0.237e9,
            2016: 0.219e9,
            2017: 0.219e9
          },
          label: "iOS",
          ordinate: "New Smartphone Shipments, Global",
          // Todo: This should be part of a canonical lib.
          yTicks: label => {
            return FormatUtils.formatInteger(label);
          },
          yBeginAtZero: true,
          fill: true,
          borderColor: COLORS.BLUSH,
          fillColor: COLORS.BLUSH
        },
        {
          type: "bar",
          data: {
            2009: 0.136e9,
            2010: 0.184e9,
            2011: 0.154e9,
            2012: 0.083e9,
            2013: 0.057e9,
            2014: 0.044e9,
            2015: 0.031e9,
            2016: 0.009e9,
            2017: 0.004e9
          },
          label: "Other",
          ordinate: "New Smartphone Shipments, Global",
          // Todo: This should be part of a canonical lib.
          yTicks: label => {
            return FormatUtils.formatInteger(label);
          },
          yBeginAtZero: true,
          fill: true,
          borderColor: COLORS.VIVID_VIOLET,
          fillColor: COLORS.VIVID_VIOLET
        }
      ]
    };
  }

  static _getThirdPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "Smartphone users in 2015",
        data: {
          China: 650e6,
          India: 250e6,
          "United States": 210e6,
          Indonesia: 40e6,
          Brazil: 60e6,
          "South Korea": 34e6,
          Japan: 60e6,
          Germany: 75e6,
          Mexico: 40e6,
          "United Kingdom": 42e6
        },
        remove: new Set(["Antarctica"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatInteger(item)
      }
    };
  }

  static _getFourthPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "Smartphone users in 2018",
        data: {
          China: 760e6,
          India: 300e6,
          "United States": 226e6,
          Indonesia: 55e6,
          Brazil: 79e6,
          "South Korea": 36e6,
          Japan: 63e6,
          Germany: 80e6,
          Mexico: 53e6,
          "United Kingdom": 45e6
        },
        remove: new Set(["Antarctica"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatInteger(item)
      }
    };
  }

  static _getFifthPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "Projected smartphone users in 2021",
        data: {
          China: 860e6,
          India: 410e6,
          "United States": 230e6,
          Indonesia: 70e6,
          Brazil: 96e6,
          "South Korea": 40e6,
          Japan: 65e6,
          Germany: 85e6,
          Mexico: 70e6,
          "United Kingdom": 46e6
        },
        remove: new Set(["Antarctica"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatInteger(item)
      }
    };
  }

  static _getSixthPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "Smartphone market penetration in 2018",
        data: {
          China: 52,
          India: 22,
          "United States": 69,
          Indonesia: 21,
          Brazil: 38,
          "South Korea": 72,
          Japan: 50,
          Germany: 69,
          Mexico: 41,
          "United Kingdom": 69
        },
        remove: new Set(["Antarctica"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatPercentage(item)
      }
    };
  }

  static _getSeventhPageData(penetrationPercentage: Number) {
    return {
      special: "india-basic",
      config: {
        title: "Smartphone users in India",
        labels: [2015, 2018, 2021],
        showGrid: true,
        tooltipFunction: x =>
          x.datasetIndex === 0
            ? FormatUtils.formatInteger(x.yLabel)
            : x.datasetIndex === 1
            ? FormatUtils.formatPercentage(x.yLabel)
            : x.yLabel,
        graphs: [
          {
            type: "bar",
            data: {
              2015: 250e6,
              2018: 300e6,
              2021: 1.386e9 * penetrationPercentage * 1e-2
            },
            label: "Smartphone Users",
            ordinate: "Smartphone Users",
            yTicks: label => FormatUtils.formatInteger(label),
            yBeginAtZero: true,
            fill: true,
            borderColor: COLORS.GOVERNOR_BAY,
            fillColor: COLORS.GOVERNOR_BAY
          }
        ]
      }
    };
  }

  _setData = (data: Object, scrollTo: Number) => {
    this.setState({ data, scrollTo });
  };

  render() {
    return (
      <Container fluid className="flex-fill d-flex flex-column">
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this._onResize}
        />
        <Row className="flex-fill pr-2">
          <Col
            xs="12"
            sm="7"
            md="8"
            lg="9"
            xl="9"
            className="d-flex flex-fill sticky-top align-self-start"
            style={{ paddingRight: "10px" }}
          >
            <div className="chart-container sticky-top">
              <Chart fluid data={this.state.data} />
            </div>
          </Col>
          <Col
            xs="0"
            sm="5"
            md="4"
            lg="3"
            xl="3"
            className="hide-xs d-flex flex-fill align-items-center"
          >
            <Container fluid className="flex-fill d-flex flex-column">
              <Row>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getFirstPageData(), 1)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[1] = ref)}>
                    Internet user growth grew has slowed down to 7% (compared to
                    12% growth in 2017)
                  </p>
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getFirstPageData(), 1)
                    }
                  />
                </div>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getSecondPageData(), 2)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[2] = ref)}>
                    Global smartphone shipments did not grow in 2018 (compared
                    to 2% Y-o-Y growth in 2017)
                  </p>
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getSecondPageData(), 2)
                    }
                  />
                </div>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getThirdPageData(), 3)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[3] = ref)}>
                    {"Smartphone user growth has been stronger in emerging " +
                      "markets in recent years."}
                  </p>
                  <p className="App-text">
                    {"The graph shows the number of smartphone users in 2015."}
                  </p>
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getThirdPageData(), 3)
                    }
                  />
                </div>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getFourthPageData(), 4)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[4] = ref)}>
                    {"The graph shows the number of smartphone users in 2018."}
                  </p>
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getFourthPageData(), 4)
                    }
                  />
                </div>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getFifthPageData(), 5)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[5] = ref)}>
                    {"This trend is likely to continue due to low smartphone " +
                      "penetration in emerging markets (typically < 50%)."}
                  </p>
                  <p className="App-text">
                    {"The graph shows the projected number of smartphone " +
                      "users in 2021."}
                  </p>
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getFifthPageData(), 5)
                    }
                  />
                </div>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getSixthPageData(), 6)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[6] = ref)}>
                    {"The graph shows the percentage market penetration of " +
                      "smartphones in 2018."}
                  </p>
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getSixthPageData(), 6)
                    }
                  />
                </div>
                <div
                  className={
                    "chart-container d-flex flex-column justify-content-center"
                  }
                >
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getSeventhPageData(40), 7)
                    }
                  />
                  <p className="App-text" ref={ref => (this.scroll[7] = ref)}>
                    {"Special focus on India, where the number of smartphone " +
                      "users is likely to double in the next 3-4 years."}
                  </p>
                  <p className="App-text" ref={ref => (this.scroll[7] = ref)}>
                    {"Use the buttons below to vary the projected market " +
                      "penetration for the year 2021."}
                  </p>
                  <ButtonRadio
                    options={[20, 30, 40, 50, 60]}
                    defaultOptionIndex={2}
                    displayFunction={option =>
                      FormatUtils.formatPercentage(option)
                    }
                    callback={option =>
                      this._setData(
                        TechTrendsLayout._getSeventhPageData(option),
                        1
                      )
                    }
                  />
                  <Waypoint
                    onEnter={() =>
                      this._setData(TechTrendsLayout._getSeventhPageData(40), 7)
                    }
                  />
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TechTrendsLayout;

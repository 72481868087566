import { HorizontalBar, Chart } from "react-chartjs-2";

import NumberUtils from "../../../utils/NumberUtils";

// Only rounds when bars are not stacked.
// TODO: Make this work for stacked bars as well.
class RoundedBar extends HorizontalBar {
  constructor(props) {
    super(props);

    const IsStacked = (bar: Object) =>
      bar._chart.config.options.scales.xAxes[0].stacked || false;

    const GetCornerRadius = (bar: Object) => {
      const NUM_DATASETS = bar._chart.config.data.datasets.reduce(
        (total, dataset) =>
          dataset.type.toLowerCase() === "bar" ? total + 1 : total,
        0
      );
      return NUM_DATASETS === 1 || !IsStacked(bar)
        ? bar._chart.config.options.cornerRadius
        : 0;
    };

    Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
      draw() {
        let ctx = this._chart.ctx;
        let vm = this._view;
        let left, right, top, bottom, signX, signY, borderSkipped;
        let borderWidth = vm.borderWidth;

        // If radius is less than 0 or is large enough to cause drawing errors a
        // max radius is imposed. If cornerRadius is not defined set it to 0.
        let cornerRadius = GetCornerRadius(this);
        if (cornerRadius < 0) {
          cornerRadius = 0;
        }
        if (typeof cornerRadius == "undefined") {
          cornerRadius = 0;
        }

        if (!vm.horizontal) {
          // bar
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top ? 1 : -1;
          borderSkipped = vm.borderSkipped || "bottom";
        } else {
          // horizontal bar
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left ? 1 : -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || "left";
        }

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
          // borderWidth should be less than bar width and bar height.
          let barSize = Math.min(
            Math.abs(left - right),
            Math.abs(top - bottom)
          );
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          let halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          let borderLeft =
            left + (borderSkipped !== "left" ? halfStroke * signX : 0);
          let borderRight =
            right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
          let borderTop =
            top + (borderSkipped !== "top" ? halfStroke * signY : 0);
          let borderBottom =
            bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
          // not become a vertical line?
          if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
          }
          // not become a horizontal line?
          if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
          }
        }

        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        // Corner points, from bottom-left to bottom-right clockwise
        // | 1 2 |
        // | 0 3 |
        let corners = [
          [left, bottom],
          [left, top],
          [right, top],
          [right, bottom]
        ];

        // Find first (starting) corner with fallback to 'bottom'
        let borders = ["bottom", "left", "top", "right"];
        let startCorner = borders.indexOf(borderSkipped, 0);
        if (startCorner === -1) {
          startCorner = 0;
        }

        function cornerAt(index) {
          return corners[(startCorner + index) % 4];
        }

        // Draw rectangle from 'startCorner'
        let corner = cornerAt(0);
        ctx.moveTo(corner[0], corner[1]);

        let width, height;
        let x, y, x_tl, y_tl, x_tr, y_tr, x_bl, y_bl, x_br, y_br;
        for (let i = 1; i < 4; i++) {
          corner = cornerAt(i);

          width = corners[2][0] - corners[1][0];
          height = corners[0][1] - corners[1][1];
          x = corners[1][0];
          y = corners[1][1];

          // Fix radius being too large
          let radius = NumberUtils.Clamp(
            cornerRadius,
            0,
            Math.floor(Math.min(Math.abs(height), Math.abs(width)) / 2)
          );

          // Height cannot be negative in a horizontal bar chart.
          if (width < 0) {
            // Negative values in a horizontal bar chart
            x_tl = x + width;
            x_tr = x;
            y_tl = y;
            y_tr = y;

            x_bl = x + width;
            x_br = x;
            y_bl = y + height;
            y_br = y + height;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br, y_br);
            ctx.lineTo(x_tr, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
          } else {
            // Positive Value
            x_tl = x;
            x_tr = x + width;
            y_tl = y;
            y_tr = y;

            x_bl = x;
            x_br = x + width;
            y_bl = y + height;
            y_br = y + height;

            // Curved edges only on the top side
            ctx.moveTo(x_tl, y_tl);
            ctx.lineTo(x_tr - radius, y_tr);
            ctx.quadraticCurveTo(x_tr, y_tr, x_tr, y_tr + radius);
            ctx.lineTo(x_br, y_br - radius);
            ctx.quadraticCurveTo(x_br, y_br, x_br - radius, y_br);
            ctx.lineTo(x_bl, y_bl);
            ctx.lineTo(x_tl, y_tl);
          }
        }

        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }
      }
    });
  }
}

export default RoundedBar;

const HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

const MakeColorObject = (r: Number, g: Number, b: Number) => {
  return () => ({ r, g, b });
};

const RGBToHex = (color: Object) => {
  return "rgb(" + color.r + "," + color.g + "," + color.b + ")";
};

const HexToRGB = (hex: String) => {
  let result = HEX_REGEX.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export default {
  MakeColorObject,
  RGBToHex,
  HexToRGB
};

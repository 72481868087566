import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardColumns, Container } from "reactstrap";

import Header from "./home/Header";

class HomePage extends React.Component {
  render() {
    return (
      <main>
        <Header />
        <Container fluid className="flex-fill d-flex flex-column">
          <CardColumns>
            <Link style={{ textDecoration: "none" }} to="/techtrends">
              <Card body inverse color="primary">
                <CardBody>
                  <h3>Technological trends in Global Smartphone market</h3>
                </CardBody>
              </Card>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/talenttrends">
              <Card body inverse color="primary">
                <CardBody>
                  <h3>Global talent trends</h3>
                </CardBody>
              </Card>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/">
              <Card body inverse color="primary">
                <CardBody>
                  <h3>Todo 1</h3>
                </CardBody>
              </Card>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/">
              <Card body inverse color="primary">
                <CardBody>
                  <h3>Todo 2</h3>
                </CardBody>
              </Card>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/">
              <Card body inverse color="primary">
                <CardBody>
                  <h3>Todo 3</h3>
                </CardBody>
              </Card>
            </Link>
          </CardColumns>
        </Container>
      </main>
    );
  }
}

export default HomePage;

function formatInteger(z: Number) {
  const FormatWholeNumber = (w: Number) => {
    if (w >= 1000000000) {
      return Math.round((w / 1000000000) * 10) / 10 + "B";
    } else if (w >= 1000000) {
      return Math.round((w / 1000000) * 10) / 10 + "M";
    } else if (w >= 1000) {
      return Math.round((w / 1000) * 10) / 10 + "K";
    } else {
      return w;
    }
  };

  if (z >= 0) {
    return FormatWholeNumber(z);
  } else {
    return "-" + FormatWholeNumber(-z);
  }
}

function formatPercentage(value: Number) {
  if (Number.isInteger(value)) {
    return Number.parseInt(value) + "%";
  } else {
    return Number.parseFloat(value).toFixed(1) + "%";
  }
}

export default {
  formatInteger,
  formatPercentage
};

import classNames from "classnames";
import { geoMercator, geoPath } from "d3";
import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { mesh as TJMesh } from "topojson";

import IndiaOutline from "../../assets/data/india-outline-min";
import Constants from "../../common/constants";
import Utils from "../../utils/Utils";
import BasicChart from "./BasicChart";

class IndiaOutlineBasic extends React.Component {
  props: {
    config: Object
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.invisibleState = {
      states: {},
      tooltipContainer: null,
      tooltip: null
    };
  }

  componentDidMount() {
    this._onResize();
  }

  _onResize = () => {
    if (!Utils.isNull(this.container)) {
      this.setState({
        dimensions: {
          width: this.container.offsetWidth,
          height: this.container.offsetHeight
        }
      });
    }
  };

  _renderChart() {
    if (Utils.isDataEmpty(this.state.dimensions)) return null;

    let width = this.state.dimensions.width || 0;
    let height = this.state.dimensions.height || 0;

    let projection = geoMercator()
      .center([80, 23])
      .scale(height * 1.5)
      .translate([width / 2, height / 2])
      .precision(0.1);
    let path = geoPath().projection(projection);

    return (
      <svg width={width} height={height} className={"overlay-outline"}>
        <path
          className={"india-outline"}
          d={path(TJMesh(IndiaOutline, IndiaOutline.objects["india-outline"]))}
          id={"india-outline"}
          style={{
            stroke: "#000000",
            strokeWidth: "2px",
            strokeOpacity: 0.2,
            fillOpacity: 0
          }}
        />
      </svg>
    );
  }

  render() {
    return (
      <div className={"chart-container"} ref={ref => (this.container = ref)}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this._onResize}
        />
        {this._renderChart()}
        <div
          className={classNames("chart-internal-container")}
          style={{
            paddingLeft: Constants.CHART_PADDING_HORIZONTAL_TRIPLE,
            paddingRight: Constants.CHART_PADDING_HORIZONTAL_TRIPLE,
            paddingTop: Constants.CHART_PADDING_VERTICAL_REGULAR,
            paddingBottom: Constants.CHART_PADDING_VERTICAL_REGULAR
          }}
        >
          <BasicChart data={this.props.config} />
        </div>
      </div>
    );
  }
}

export default IndiaOutlineBasic;

import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { Waypoint } from "react-waypoint";
import { Col, Container, Row } from "reactstrap";

import * as COLORS from "../common/colors";
import Chart from "../components/charts/Chart";
import FormatUtils from "../utils/FormatUtils";

class TalentTrendsLayout extends React.Component {
  constructor(props) {
    super(props);

    this.scroll = {};

    this.state = {
      data: TalentTrendsLayout._getFirstPageData()
    };
  }

  static _getFirstPageData() {
    return {
      title: "Percentage of professionals who agree",
      labels: [
        "Soft skills",
        "Work flexibility",
        "Anti-harassment",
        "Pay transparency"
      ],
      showGrid: false,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.xLabel)
          : x.xLabel,
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Soft skills": 91,
            "Work flexibility": 72,
            "Anti-harassment": 71,
            "Pay transparency": 53
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        }
      ]
    };
  }

  static _getSecondPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "% of professionals supporting",
        data: {
          Mexico: 96,
          Brazil: 95,
          India: 95,
          Italy: 95,
          Malaysia: 95,
          Singapore: 95,
          Philippines: 95,
          Spain: 95,
          Canada: 95,
          China: 93,
          "Saudi Arabia": 93,
          "United Arab Emirates": 93,
          Jordan: 93,
          Israel: 93,
          Egypt: 93,
          Argentina: 92,
          Australia: 91,
          "United States": 90,
          "United Kingdom": 90,
          Ireland: 90,
          France: 89,
          Germany: 88,
          Netherlands: 85,
          Denmark: 82,
          Estonia: 82,
          Finland: 82,
          Iceland: 82,
          Latvia: 82,
          Lithuania: 82,
          Norway: 82,
          Sweden: 82
        },
        remove: new Set(["Antarctica", "Greenland"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatPercentage(item)
      }
    };
  }

  static _getThirdPageData() {
    return {
      title: "% of professionals that hire for",
      labels: ["Soft skills", "Soft and hard skills", "Hard skills"],
      tooltipFunction: (x, data) =>
        data.labels[x.index] +
        ": " +
        FormatUtils.formatPercentage(
          data.datasets[x.datasetIndex].data[x.index]
        ),
      graphs: [
        {
          type: "doughnut",
          data: {
            "Soft skills": 30,
            "Soft and hard skills": 62,
            "Hard skills": 8
          },
          color: {
            start: COLORS.FRENCH_ROSE,
            end: COLORS.GOVERNOR_BAY
          }
        }
      ]
    };
  }

  static _getFourthPageData() {
    return {
      title: "% of professionals that fire candidates for missing",
      labels: ["Soft skills", "Soft and hard skills", "Hard skills"],
      tooltipFunction: (x, data) =>
        data.labels[x.index] +
        ": " +
        FormatUtils.formatPercentage(
          data.datasets[x.datasetIndex].data[x.index]
        ),
      graphs: [
        {
          type: "doughnut",
          data: {
            "Soft skills": 45,
            "Soft and hard skills": 44,
            "Hard skills": 11
          },
          color: {
            start: COLORS.FRENCH_ROSE,
            end: COLORS.GOVERNOR_BAY
          }
        }
      ]
    };
  }

  static _getFifthPageData() {
    return {
      title: "% of professionals whose company uses these methods",
      labels: [
        "Behavioral questions",
        "Reading body language",
        "Situational questions",
        "Projects",
        "Tech-based assessments"
      ],
      showGrid: false,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.xLabel)
          : x.xLabel,
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Behavioral questions": 75,
            "Reading body language": 70,
            "Situational questions": 58,
            Projects: 31,
            "Tech-based assessments": 17
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        }
      ]
    };
  }

  static _getSixthPageData() {
    return {
      title:
        "% of professionals who value work flexibility when considering a job",
      labels: [2013, 2014, 2015, 2016, 2017],
      showGrid: true,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.yLabel)
          : x.yLabel,
      graphs: [
        {
          type: "line",
          data: {
            2013: 25,
            2014: 26,
            2015: 27,
            2016: 28,
            2017: 31
          },
          yTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          yBeginAtZero: true,
          fill: false,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY,
          xTickSize: 20,
          yTickSize: 20,
          borderWidth: 3
        }
      ]
    };
  }

  static _getSeventhPageData() {
    return {
      title: "Remote work allowed by industry",
      labels: [
        "Software and IT",
        "Finance",
        "Corporate Services",
        "Healthcare",
        "Manufacturing"
      ],
      showGrid: false,
      stacked: true,
      tooltipFunction: (x, data) =>
        data.datasets[x.datasetIndex].label +
        ": " +
        FormatUtils.formatPercentage(x.xLabel),
      graphs: [
        {
          type: "horizontalBar",
          label: "Allowed",
          data: {
            "Software and IT": 72,
            Finance: 62,
            "Corporate Services": 57,
            Healthcare: 43,
            Manufacturing: 43
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        },
        {
          type: "horizontalBar",
          label: "Only in special circumstances",
          data: {
            "Software and IT": 24,
            Finance: 26,
            "Corporate Services": 31,
            Healthcare: 45,
            Manufacturing: 38
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.PLUM,
          fillColor: COLORS.PLUM
        },
        {
          type: "horizontalBar",
          label: "Not allowed",
          data: {
            "Software and IT": 4,
            Finance: 12,
            "Corporate Services": 12,
            Healthcare: 12,
            Manufacturing: 19
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.FRENCH_ROSE,
          fillColor: COLORS.FRENCH_ROSE
        }
      ]
    };
  }

  static _getEighthPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "% of professionals who deem it important",
        data: {
          Denmark: 85,
          Estonia: 85,
          Finland: 85,
          Iceland: 85,
          Latvia: 85,
          Lithuania: 85,
          Norway: 85,
          Sweden: 85,
          Australia: 84,
          Spain: 83,
          Germany: 75,
          "United States": 75,
          "United Kingdom": 75,
          Ireland: 75,
          France: 72,
          Canada: 71,
          Malaysia: 71,
          Singapore: 71,
          Philippines: 71,
          Argentina: 69,
          Netherlands: 69,
          Italy: 68,
          India: 67,
          Brazil: 66,
          "Saudi Arabia": 65,
          "United Arab Emirates": 65,
          Jordan: 65,
          Israel: 65,
          Egypt: 65,
          Mexico: 64,
          China: 52
        },
        remove: new Set(["Antarctica", "Greenland"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatPercentage(item)
      }
    };
  }

  static _getNinthPageData() {
    return {
      title: "Percentage of professionals who agree",
      labels: [
        "Improves employee work-life balance",
        "Encourages retention",
        "Attracts candidates",
        "Increases productivity",
        "Expands available talent pool"
      ],
      showGrid: false,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.xLabel)
          : x.xLabel,
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Improves employee work-life balance": 77,
            "Encourages retention": 54,
            "Attracts candidates": 51,
            "Increases productivity": 42,
            "Expands available talent pool": 38
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        }
      ]
    };
  }

  static _getTenthPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "% of professionals who deem it important",
        data: {
          India: 87,
          Mexico: 81,
          Brazil: 78,
          Canada: 78,
          "Saudi Arabia": 78,
          "United Arab Emirates": 78,
          Jordan: 78,
          Israel: 78,
          Egypt: 78,
          Australia: 76,
          Malaysia: 76,
          Singapore: 76,
          Philippines: 76,
          "United States": 74,
          Argentina: 72,
          "United Kingdom": 70,
          Ireland: 70,
          Italy: 57,
          Spain: 56,
          France: 54,
          China: 52,
          Netherlands: 52,
          Denmark: 51,
          Estonia: 51,
          Finland: 51,
          Iceland: 51,
          Latvia: 51,
          Lithuania: 51,
          Norway: 51,
          Sweden: 51,
          Germany: 47
        },
        remove: new Set(["Antarctica", "Greenland"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatPercentage(item)
      }
    };
  }

  static _getEleventhPageData() {
    return {
      title:
        "Percentage of professionals whose companies use these " +
        "anti-harassment tactics",
      labels: [
        "Highlight existing policies",
        "Promote ways to safely report",
        "Add or improve policies",
        "Establish zero-tolerance policy",
        "Hold training sessions",
        "Increase gender diversity of leadership",
        "Add more ways to safely report",
        "Add or improve investigation practices",
        "Use third party to audit culture and practices",
        "Use third party to receive reports"
      ],
      showGrid: false,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.xLabel)
          : x.xLabel,
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Highlight existing policies": 37,
            "Promote ways to safely report": 37,
            "Add or improve policies": 35,
            "Establish zero-tolerance policy": 34,
            "Hold training sessions": 33,
            "Increase gender diversity of leadership": 26,
            "Add more ways to safely report": 25,
            "Add or improve investigation practices": 18,
            "Use third party to audit culture and practices": 9,
            "Use third party to receive reports": 9
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        }
      ]
    };
  }

  static _getTwelfthPageData() {
    return {
      title:
        "Percentage of professionals who support these tactics, grouped " +
        "by gender",
      labels: [
        "Promote ways to safely report",
        "Establish zero-tolerance policy",
        "Add more ways to safely report",
        "Hold training sessions",
        "Increase gender diversity of leadership",
        "Add or improve policies"
      ],
      showGrid: false,
      tooltipFunction: (x, data) =>
        data.datasets[x.datasetIndex].label +
        ": " +
        FormatUtils.formatPercentage(x.xLabel),
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Promote ways to safely report": 65,
            "Establish zero-tolerance policy": 65,
            "Add more ways to safely report": 56,
            "Hold training sessions": 53,
            "Increase gender diversity of leadership": 51,
            "Add or improve policies": 44
          },
          xTicks: label => FormatUtils.formatPercentage(label),
          xBeginAtZero: true,
          fill: true,
          label: "Women",
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        },
        {
          label: "Men",
          type: "horizontalBar",
          data: {
            "Promote ways to safely report": 59,
            "Establish zero-tolerance policy": 59,
            "Add more ways to safely report": 51,
            "Hold training sessions": 51,
            "Increase gender diversity of leadership": 37,
            "Add or improve policies": 39
          },
          xTicks: label => FormatUtils.formatPercentage(label),
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.FRENCH_ROSE,
          fillColor: COLORS.FRENCH_ROSE
        }
      ]
    };
  }

  static _getThirteenthPageData() {
    return {
      title:
        "Percentage of professionals whose company took actions in 2017, " +
        "grouped by region",
      labels: [
        "Add ways to safely report",
        "Increase gender diversity of leadership",
        "Hold training sessions",
        "Establish zero-tolerance policy",
        "Highlight existing policies"
      ],
      showGrid: false,
      tooltipFunction: (x, data) =>
        data.datasets[x.datasetIndex].label +
        ": " +
        FormatUtils.formatPercentage(x.xLabel),
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Add ways to safely report": 34,
            "Increase gender diversity of leadership": 34,
            "Hold training sessions": 44,
            "Establish zero-tolerance policy": 47,
            "Highlight existing policies": 41
          },
          xTicks: label => FormatUtils.formatPercentage(label),
          xBeginAtZero: true,
          fill: true,
          label: "Asia-Pacific",
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        },
        {
          type: "horizontalBar",
          data: {
            "Add ways to safely report": 22,
            "Increase gender diversity of leadership": 22,
            "Hold training sessions": 20,
            "Establish zero-tolerance policy": 28,
            "Highlight existing policies": 27
          },
          xTicks: label => FormatUtils.formatPercentage(label),
          xBeginAtZero: true,
          fill: true,
          label: "Europe, M.E. and Africa",
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.VIVID_VIOLET,
          fillColor: COLORS.VIVID_VIOLET
        },
        {
          type: "horizontalBar",
          data: {
            "Add ways to safely report": 25,
            "Increase gender diversity of leadership": 23,
            "Hold training sessions": 17,
            "Establish zero-tolerance policy": 22,
            "Highlight existing policies": 25
          },
          xTicks: label => FormatUtils.formatPercentage(label),
          xBeginAtZero: true,
          fill: true,
          label: "Latin America",
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.BLUSH,
          fillColor: COLORS.BLUSH
        },
        {
          type: "horizontalBar",
          data: {
            "Add ways to safely report": 24,
            "Increase gender diversity of leadership": 25,
            "Hold training sessions": 42,
            "Establish zero-tolerance policy": 35,
            "Highlight existing policies": 45
          },
          xTicks: label => FormatUtils.formatPercentage(label),
          xBeginAtZero: true,
          fill: true,
          label: "North America",
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.FRENCH_ROSE,
          fillColor: COLORS.FRENCH_ROSE
        }
      ]
    };
  }

  static _getFourteenthPageData() {
    return {
      special: "world",
      config: {
        legendTitle: "% of professionals who value pay transparency",
        data: {
          Argentina: 81,
          Mexico: 69,
          Spain: 64,
          China: 61,
          India: 57,
          Malaysia: 56,
          Singapore: 56,
          Philippines: 56,
          Brazil: 55,
          Canada: 55,
          "Saudi Arabia": 54,
          "United Arab Emirates": 54,
          Jordan: 54,
          Israel: 54,
          Egypt: 54,
          "United States": 52,
          Australia: 51,
          France: 50,
          "United Kingdom": 50,
          Ireland: 50,
          Italy: 49,
          Netherlands: 44,
          Denmark: 44,
          Estonia: 44,
          Finland: 44,
          Iceland: 44,
          Latvia: 44,
          Lithuania: 44,
          Norway: 44,
          Sweden: 44,
          Germany: 34
        },
        remove: new Set(["Antarctica", "Greenland"]),
        color: {
          start: COLORS.FRENCH_ROSE,
          end: COLORS.GOVERNOR_BAY,
          missing: COLORS.ALTO,
          count: 5
        },
        legendLabel: item => FormatUtils.formatPercentage(item)
      }
    };
  }

  static _getFifteenthPageData() {
    return {
      title:
        "% of professionals in companies with thoughts on pay transparency",
      labels: [
        "Share salary ranges",
        "Don't share, likely to start",
        "Don't share, unlikely to start"
      ],
      tooltipFunction: (x, data) =>
        data.labels[x.index] +
        ": " +
        FormatUtils.formatPercentage(
          data.datasets[x.datasetIndex].data[x.index]
        ),
      graphs: [
        {
          type: "doughnut",
          data: {
            "Share salary ranges": 27,
            "Don't share, likely to start": 22,
            "Don't share, unlikely to start": 51
          },
          color: {
            start: COLORS.FRENCH_ROSE,
            end: COLORS.GOVERNOR_BAY
          }
        }
      ]
    };
  }

  static _getSixteenthPageData() {
    return {
      title: "% of professionals supporting reasons against pay transparency",
      labels: [
        "Could create salary disputes",
        "Not a common practice",
        "Limits negotiation",
        "Interview would focus on pay"
      ],
      showGrid: false,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.xLabel)
          : x.xLabel,
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Could create salary disputes": 75,
            "Not a common practice": 37,
            "Limits negotiation": 34,
            "Interview would focus on pay": 31
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        }
      ]
    };
  }

  static _getSeventeenthPageData() {
    return {
      title: "% of professionals citing benefits of pay transparency",
      labels: [
        "Streamlines negotiation",
        "Ensures fair pay",
        "Filters out those who'd decline",
        "Allows interview to focus on other things"
      ],
      showGrid: false,
      tooltipFunction: x =>
        x.datasetIndex === 0
          ? FormatUtils.formatPercentage(x.xLabel)
          : x.xLabel,
      graphs: [
        {
          type: "horizontalBar",
          data: {
            "Streamlines negotiation": 57,
            "Ensures fair pay": 55,
            "Filters out those who'd decline": 54,
            "Allows interview to focus on other things": 51
          },
          xTicks: label => {
            return FormatUtils.formatPercentage(label);
          },
          xBeginAtZero: true,
          fill: true,
          xTickSize: 20,
          yTickSize: 20,
          borderColor: COLORS.GOVERNOR_BAY,
          fillColor: COLORS.GOVERNOR_BAY
        }
      ]
    };
  }

  _setData = (data: Object) => {
    this.setState({ data });
  };

  renderPage(text: String, onScroll: Function) {
    return (
      <div
        className={"chart-container d-flex flex-column justify-content-center"}
      >
        <Waypoint onEnter={() => this._setData(onScroll())} />
        <p className="App-text" ref={ref => (this.scroll[1] = ref)}>
          {text}
        </p>
        <Waypoint onEnter={() => this._setData(onScroll())} />
      </div>
    );
  }

  render() {
    return (
      <Container fluid className="flex-fill d-flex flex-column">
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this._onResize}
        />
        <Row className="flex-fill pr-2">
          <Col
            xs="12"
            sm="7"
            md="8"
            lg="9"
            xl="9"
            className="d-flex flex-fill sticky-top align-self-start"
            style={{ paddingRight: "10px" }}
          >
            <div className="chart-container sticky-top">
              <Chart fluid data={this.state.data} />
            </div>
          </Col>
          <Col
            xs="0"
            sm="5"
            md="4"
            lg="3"
            xl="3"
            className="hide-xs d-flex flex-fill align-items-center"
          >
            <Container fluid className="flex-fill d-flex flex-column">
              <Row>
                {this.renderPage(
                  "The 4 trends that talent professionals deem important to" +
                    " the future of recruiting and HR.",
                  TalentTrendsLayout._getFirstPageData
                )}
                {this.renderPage(
                  "Soft skills have always been important, and are vital to " +
                    "succeeding in career. The graph shows the percentage of " +
                    "professionals in various countries that regard soft skills " +
                    "as an important component of a candidate's profile.",
                  TalentTrendsLayout._getSecondPageData
                )}
                {this.renderPage(
                  "Majority of hiring decisions are based on the candidate’s " +
                    "soft skills.",
                  TalentTrendsLayout._getThirdPageData
                )}
                {this.renderPage(
                  "Firing decisions are also increasingly based on the " +
                    "candidate’s soft skills.",
                  TalentTrendsLayout._getFourthPageData
                )}
                {this.renderPage(
                  "Most common way that companies assess the soft skills " +
                    "today.",
                  TalentTrendsLayout._getFifthPageData
                )}
                {this.renderPage(
                  "The next most important trend is the need of a flexible " +
                    "work environment, whose demand has been steadily growing " +
                    "over the years.",
                  TalentTrendsLayout._getSixthPageData
                )}
                {this.renderPage(
                  "Companies in different industry sectors value it " +
                    "differently.",
                  TalentTrendsLayout._getSeventhPageData
                )}
                {this.renderPage(
                  "Professionals in different countries value it differently," +
                    " when considering a job.",
                  TalentTrendsLayout._getEighthPageData
                )}
                {this.renderPage(
                  "Top benefits of a flexible work environment, from the eyes" +
                    " of the talent professionals.",
                  TalentTrendsLayout._getNinthPageData
                )}
                {this.renderPage(
                  "Next up is the company’s policies and outlook against " +
                    "harassment.",
                  TalentTrendsLayout._getTenthPageData
                )}
                {this.renderPage(
                  "Companies over the years are taking stronger steps to " +
                    "prevent harassment and resolve situations, through an " +
                    "array of options.",
                  TalentTrendsLayout._getEleventhPageData
                )}
                {this.renderPage(
                  "However, not all of them are as effective as the rest. " +
                    "The mileage varies from tactic to tactic, and also between" +
                    " genders.",
                  TalentTrendsLayout._getTwelfthPageData
                )}
                {this.renderPage(
                  "Asia-Pacific is leading the charts here.",
                  TalentTrendsLayout._getThirteenthPageData
                )}
                {this.renderPage(
                  "The 4th trend is the increase in importance of pay " +
                    "transparency. Professionals in different countries value " +
                    "it differently.",
                  TalentTrendsLayout._getFourteenthPageData
                )}
                {this.renderPage(
                  "Companies don’t fully agree with this sentiment.",
                  TalentTrendsLayout._getFifteenthPageData
                )}
                {this.renderPage(
                  "Dissenters worry that the transparency could lead to " +
                    "deterioration of the work environment.",
                  TalentTrendsLayout._getSixteenthPageData
                )}
                {this.renderPage(
                  "Promoters, on the other hand see an uptick in employee " +
                    "morale, and thereby, productivity.",
                  TalentTrendsLayout._getSeventeenthPageData
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TalentTrendsLayout;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class NotFoundPage extends Component {
  render() {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: this.props.location }
        }}
      />
    );
  }
}

export default NotFoundPage;

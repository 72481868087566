// This utility helps in attaching classes to the html elements, where their
// names are a combination of multiple strings, where each string denotes some
// functionality.

import classNames from "classnames";

export const createBEM = namespace => {
  return {
    create: blockName => {
      let block = blockName;

      if (typeof namespace === "string") {
        block = `${namespace}-${blockName}`;
      }

      return {
        // Denotes a container of styles, for instance, all styles for the
        // sidebar.
        b: (...more) => {
          return classNames(block, more);
        },
        // Denotes an element in the block, for example, an icon in the sidebar.
        e: (className, ...more) => {
          return classNames(`${block}__${className}`, more);
        },
        // Denotes a modifier on an element in the block, for example, the
        // styles for when the sidebar opens and closes.
        m: (className, ...more) => {
          return classNames(`${block}--${className}`, more);
        }
      };
    }
  };
};

export const bemNames = createBEM("demo");

export default bemNames;

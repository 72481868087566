import classNames from "classnames";
import React from "react";

import Constants from "../../common/constants";
import Utils from "../../utils/Utils";
import BasicChart from "./BasicChart";
import IndiaOutlineBasic from "./IndiaOutlineBasic";
import WorldMap from "./WorldMap";

// Todo: Assumes that y axis only contains numeric data.
class Chart extends React.Component {
  props: {
    data: Object
  };

  _specialChartName() {
    return Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.special)
      ? null
      : this.props.data.special;
  }

  _renderChart() {
    let specialChartName = this._specialChartName();
    if (specialChartName === null) {
      return (
        <div
          className={classNames("chart-internal-container")}
          style={{
            paddingLeft: Constants.CHART_PADDING_HORIZONTAL_DOUBLE,
            paddingRight: Constants.CHART_PADDING_HORIZONTAL_DOUBLE,
            paddingTop: Constants.CHART_PADDING_VERTICAL_REGULAR,
            paddingBottom: Constants.CHART_PADDING_VERTICAL_REGULAR
          }}
        >
          <BasicChart data={this.props.data} />
        </div>
      );
    } else if (specialChartName === "world") {
      return <WorldMap config={this.props.data.config} />;
    } else if (specialChartName === "india-basic") {
      return <IndiaOutlineBasic config={this.props.data.config} />;
    }

    return null;
  }

  render() {
    return this._renderChart();
  }
}

export default Chart;

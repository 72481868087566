import classNames from "classnames";
import React from "react";
import { defaults, Doughnut, Line } from "react-chartjs-2";
import _ from "underscore";

import Constants from "../../common/constants";
import ColorUtils from "../../utils/ColorUtils";
import NumberUtils from "../../utils/NumberUtils";
import Utils from "../../utils/Utils";
import RoundedBar from "./extensions/RoundedBar";
import RoundedHorizontalBar from "./extensions/RoundedHorizontalBar";

defaults.global.defaultFontSize = Constants.DEFAULT_TICK_SIZE;
defaults.global.defaultFontFamily = [
  "Lato",
  "Roboto",
  "Montserrat",
  "Arial",
  "sans-serif"
].join(",");

// Todo: Assumes that y axis only contains numeric data.
class BasicChart extends React.Component {
  props: {
    data: Object
  };

  invisibleState = {
    existingOrdinates: {}
  };

  static DatasetsHaveType(datasets: Array, type: String) {
    return datasets.reduce(
      (found: Boolean, dataset: Object) =>
        found || dataset.type.toLowerCase() === type.toLowerCase(),
      false
    );
  }

  _showGrid() {
    return Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.showGrid)
      ? true
      : this.props.data.showGrid;
  }

  _stacked() {
    return Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.stacked)
      ? false
      : this.props.data.stacked;
  }

  _title() {
    return Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.title)
      ? null
      : this.props.data.title;
  }

  _titlePosition() {
    return Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.titlePosition)
      ? Constants.DEFAULT_TITLE_POSITION
      : this.props.data.titlePosition;
  }

  _labels() {
    return Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.labels)
      ? []
      : this.props.data.labels;
  }

  _addCartesianData(graph: Object, out_options: Object, out_data: Object) {
    out_data.labels = this._labels();

    let addNewOrdinate = true;
    let id = out_options.scales.yAxes.length;
    let ordinateLabel = (graph.ordinate || "").toLowerCase();
    if (
      out_options.scales.yAxes.length !== 0 &&
      _.has(this.invisibleState.existingOrdinates, ordinateLabel)
    ) {
      id = this.invisibleState.existingOrdinates[ordinateLabel];
      addNewOrdinate = false;
    }

    if (addNewOrdinate) {
      this.invisibleState.existingOrdinates[ordinateLabel] = id;
      let tickFunction = graph.yTicks || (x => x);
      out_options.scales.yAxes.push({
        id,
        barPercentage: 0.83,
        categoryPercentage: 0.8,
        gridLines: {
          display: this._showGrid() && id === 0
        },
        stacked: this._stacked(),
        position: id === 0 ? "left" : "right",
        ticks: {
          callback: tickFunction,
          fontSize: Utils.isDataEmpty(graph.yTickSize)
            ? Constants.DEFAULT_TICK_SIZE
            : graph.yTickSize,
          beginAtZero: graph.yBeginAtZero || false
        },
        scaleLabel: {
          display: !Utils.isDataEmpty(graph.ordinate),
          labelString: graph.ordinate || ""
        }
      });
    }

    // Todo: Add support for multiple abscissas.
    out_options.scales.xAxes[0] = {
      barPercentage: 0.84,
      categoryPercentage: 0.8,
      ticks: {
        callback: graph.xTicks || (x => x),
        beginAtZero: graph.xBeginAtZero || false,
        fontSize: Utils.isDataEmpty(graph.xTickSize)
          ? Constants.DEFAULT_TICK_SIZE
          : graph.xTickSize
      },
      stacked: this._stacked(),
      gridLines: {
        display: this._showGrid()
      }
    };

    let dataConfig = {
      label: graph.label || "",
      fill: graph.fill || false,
      borderWidth: 1,
      data: this._labels().map(key =>
        _.has(graph.data, key) && !Utils.isDataEmpty(graph.data[key])
          ? graph.data[key]
          : null
      ),
      yAxisID: id,
      type: graph.type
    };
    if (!Utils.isDataEmpty(graph.fillColor)) {
      dataConfig.backgroundColor = graph.fillColor;
    }
    if (!Utils.isDataEmpty(graph.borderColor)) {
      dataConfig.borderColor = graph.borderColor;
    }
    if (!Utils.isDataEmpty(graph.borderWidth)) {
      dataConfig.borderWidth = graph.borderWidth;
    }

    out_data.datasets.push(dataConfig);
  }

  // Todo: Support multiple doughnuts in one graph.
  _addDoughNutData(graph: Object, out_options: Object, out_data: Object) {
    out_data.labels = this._labels();

    let start_color = ColorUtils.HexToRGB(graph.color.start);
    let end_color = ColorUtils.HexToRGB(graph.color.end);

    let dataConfig = {
      data: out_data.labels.map(label => graph.data[label] || 0),
      type: graph.type,
      backgroundColor: out_data.labels.map((_, index) =>
        ColorUtils.RGBToHex(
          ColorUtils.MakeColorObject(
            NumberUtils.Interpolate(
              start_color.r,
              end_color.r,
              out_data.labels.length,
              index
            ),
            NumberUtils.Interpolate(
              start_color.g,
              end_color.g,
              out_data.labels.length,
              index
            ),
            NumberUtils.Interpolate(
              start_color.b,
              end_color.b,
              out_data.labels.length,
              index
            )
          )()
        )
      )
    };

    out_data.datasets.push(dataConfig);

    out_options.legend = {
      display: true
    };
  }

  _addDataset(graph: Object, out_options: Object, out_data: Object) {
    if (Constants.SUPPORTED_CARTESIAN_CHARTS.has(graph.type)) {
      this._addCartesianData(graph, out_options, out_data);
    } else if (graph.type === "doughnut") {
      this._addDoughNutData(graph, out_options, out_data);
    }
  }

  _renderChart() {
    if (
      Utils.isDataEmpty(this.props.data) ||
      Utils.isDataEmpty(this.props.data.graphs)
    ) {
      return null;
    }

    let options = {
      maintainAspectRatio: false,
      legend: {
        display: this.props.data.graphs.length > 1
      },
      title: {
        display: !Utils.isDataEmpty(this._title()),
        text: this._title(),
        position: this._titlePosition()
      },
      cornerRadius: Constants.DEFAULT_BAR_CORNER_RADIUS,
      tooltips: {
        callbacks: {
          label: this.props.data.tooltipFunction || (x => x.yLabel)
        }
      },
      scales: {
        yAxes: [],
        xAxes: []
      }
    };

    let data = { labels: [], datasets: [] };
    this.props.data.graphs.forEach(graph => {
      this._addDataset(graph, options, data);
    });

    if (Utils.isDataEmpty(data.datasets)) return null;

    // if (data.datasets[0].type === "bar") {
    //   return <RoundedBar options={options} data={data} />;
    // } else if (data.datasets[0].type === "horizontalBar") {
    //   return <RoundedHorizontalBar options={options} data={data} />;
    // } else if (data.datasets[0].type === "doughnut") {
    //   return <Doughnut options={options} data={data} />;
    // } else if (data.datasets[0].type === "line") {
    //   return <Line options={options} data={data} />;
    // }

    if (BasicChart.DatasetsHaveType(data.datasets, "bar")) {
      return <RoundedBar options={options} data={data} />;
    } else if (BasicChart.DatasetsHaveType(data.datasets, "horizontalBar")) {
      return <RoundedHorizontalBar options={options} data={data} />;
    } else if (data.datasets[0].type === "doughnut") {
      return <Doughnut options={options} data={data} />;
    } else if (data.datasets[0].type === "line") {
      return <Line options={options} data={data} />;
    }

    return null;
  }

  render() {
    return (
      <div className={classNames("chart-internal-container")}>
        {this._renderChart()}
      </div>
    );
  }
}

export default BasicChart;

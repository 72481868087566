import {
  geoGraticule,
  geoMercator,
  geoPath,
  max as d3Max,
  min as d3Min,
  range as d3Range,
  scaleQuantize,
  select as d3Select
} from "d3";
import { legendColor } from "d3-svg-legend";
import "d3-transition";
import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { feature as TJFeature, mesh as TJMesh } from "topojson";
import * as _ from "underscore";

import WorldTopology from "../../assets/data/world-topo-min";
import ColorUtils from "../../utils/ColorUtils";
import NumberUtils from "../../utils/NumberUtils";
import Utils from "../../utils/Utils";
import Tooltip from "./Tooltip";

const HEIGHT_SCALE = 3 / 2.2; // Helps hide Antarctica.

class WorldMap extends React.Component {
  props: {
    config: Object
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.invisibleState = {
      countries: {},
      tooltipContainer: null,
      tooltip: null
    };
  }

  componentDidMount() {
    this._onResize();
  }

  _onResize = () => {
    if (!Utils.isNull(this.container)) {
      this.setState({
        dimensions: {
          width: this.container.offsetWidth,
          height: this.container.offsetHeight
        }
      });
    }
  };

  _onMouseMove = (event: Object, country: Object) => {
    if (!_.has(this.props.config.data, country.properties.name)) return;

    let ref = this.invisibleState.countries[country.properties.name];
    if (Utils.isNull(ref)) return;
    ref.style.fillOpacity = 0.8;

    if (
      Utils.isDataEmpty(country) ||
      Utils.isDataEmpty(this.invisibleState.tooltipContainer) ||
      !Utils.isFunction(
        this.invisibleState.tooltipContainer.getBoundingClientRect
      ) ||
      Utils.isDataEmpty(this.invisibleState.tooltip) ||
      !Utils.isFunction(this.invisibleState.tooltip.setTooltip)
    ) {
      return;
    }
    this.invisibleState.tooltip.setTooltip(
      country.properties.name,
      this.props.config.data[country.properties.name]
        ? this.props.config.legendLabel(
            this.props.config.data[country.properties.name]
          )
        : ""
    );

    this.invisibleState.tooltipContainer.style.top = event.clientY + 15 + "px";
    this.invisibleState.tooltipContainer.style.left =
      event.clientX - 100 + "px";
    this.invisibleState.tooltipContainer.style.display = "initial";
  };

  _onMouseOut = (country: Object) => {
    if (!_.has(this.props.config.data, country.properties.name)) return;

    let ref = this.invisibleState.countries[country.properties.name];
    if (Utils.isNull(ref)) return;
    ref.style.fillOpacity = 1.0;

    if (Utils.isDataEmpty(this.invisibleState.tooltip)) {
      return;
    }
    this.invisibleState.tooltipContainer.style.display = "none";
  };

  _renderLegend(scale: Function, svg: Object) {
    if (
      !Utils.isFunction(scale.domain) ||
      !Utils.isFunction(scale.thresholds)
    ) {
      return null;
    }

    let limits = scale.domain().slice();
    limits.splice(1, 0, ...scale.thresholds());
    let labels = [];
    for (let i = 1; i < limits.length; i++) {
      labels.push(
        this.props.config.legendLabel(limits[i - 1]) +
          " to " +
          this.props.config.legendLabel(limits[i])
      );
    }

    let legend = legendColor()
      .labels(label => labels[label.i])
      .title(this.props.config.legendTitle)
      .titleWidth(0.2 * (this.state.dimensions.width || 0))
      .cells(this.props.config.color.count)
      .scale(scale);

    let xMove = 0.01 * (this.state.dimensions.width || 0);
    let yMove = 0.7 * (this.state.dimensions.height || 0);

    let g = (
      <g
        className={"legend-threshold"}
        style={{ transform: `translate(${xMove}px, ${yMove}px)` }}
      />
    );
    svg.select(".legend-threshold").call(legend);

    return g;
  }

  _renderChart() {
    if (Utils.isDataEmpty(this.state.dimensions)) return null;

    let rgb = ColorUtils.HexToRGB(this.props.config.color.start);
    let startColors = ColorUtils.MakeColorObject(rgb.r, rgb.g, rgb.b)();
    rgb = ColorUtils.HexToRGB(this.props.config.color.end);
    let endColors = ColorUtils.MakeColorObject(rgb.r, rgb.g, rgb.b)();

    let colors = [];
    for (let i = 0; i < this.props.config.color.count; i++) {
      colors.push(
        ColorUtils.MakeColorObject(
          NumberUtils.Interpolate(
            startColors.r,
            endColors.r,
            this.props.config.color.count,
            i
          ),
          NumberUtils.Interpolate(
            startColors.g,
            endColors.g,
            this.props.config.color.count,
            i
          ),
          NumberUtils.Interpolate(
            startColors.b,
            endColors.b,
            this.props.config.color.count,
            i
          )
        )
      );
    }

    let width = this.state.dimensions.width || 0;
    let height = this.state.dimensions.height * HEIGHT_SCALE || 0;

    let projection = geoMercator()
      .scale((width + 1) / 2 / Math.PI)
      .translate([width / 2, height / 2])
      .precision(0.1);
    let path = geoPath().projection(projection);
    let graticule = geoGraticule();

    let quantize = scaleQuantize()
      .domain([0, 1.0])
      .range(
        d3Range(this.props.config.color.count).map(i =>
          ColorUtils.RGBToHex(colors[i]())
        )
      );
    quantize.domain([
      d3Min(Object.values(this.props.config.data)),
      d3Max(Object.values(this.props.config.data))
    ]);

    let countries = TJFeature(WorldTopology, WorldTopology.objects.countries)
      .features;

    return (
      <svg width={width} height={height / HEIGHT_SCALE}>
        <path className="graticule" d={path(graticule())} />
        <path className="choropleth" d={path(graticule())} />
        {this._renderLegend(quantize, d3Select("svg"))}
        <g>
          <path
            className="equator"
            d={path({
              type: "LineString",
              coordinates: [[-180, 0], [-90, 0], [0, 0], [90, 0], [180, 0]]
            })}
          />
          {countries.map(country => {
            if (this.props.config.remove.has(country.properties.name)) {
              return null;
            }

            let color = this.props.config.color.missing;
            if (_.has(this.props.config.data, country.properties.name)) {
              color = quantize(this.props.config.data[country.properties.name]);
            }
            return (
              <path
                className="country"
                d={path(country)}
                id={country.id}
                title={country.properties.name}
                ref={ref => {
                  this.invisibleState.countries[country.properties.name] = ref;
                }}
                style={{
                  fill: color,
                  fillOpacity: 1.0
                }}
                onMouseMove={event => this._onMouseMove(event, country, width)}
                onMouseOut={() => this._onMouseOut(country)}
              />
            );
          })}
          <path
            className="boundary"
            d={path(
              TJMesh(
                WorldTopology,
                WorldTopology.objects.countries,
                (a, b) => a !== b
              )
            )}
          />
        </g>
      </svg>
    );
  }

  render() {
    return (
      <div className={"chart-container"} ref={ref => (this.container = ref)}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this._onResize}
        />
        <div
          className="world-map-tooltip-container"
          ref={ref => (this.invisibleState.tooltipContainer = ref)}
        >
          <Tooltip ref={ref => (this.invisibleState.tooltip = ref)} />
        </div>
        {this._renderChart()}
      </div>
    );
  }
}

export default WorldMap;

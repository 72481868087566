const Interpolate = (
  start: Number,
  end: Number,
  steps: Number,
  count: Number
) => {
  return Math.floor(start + ((end - start) * count) / steps);
};

const Clamp = (x: Number, left: Number, right: Number) =>
  Math.min(Math.max(x, left), right);

export default {
  Interpolate,
  Clamp
};

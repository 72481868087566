import React from "react";

class Tooltip extends React.Component {
  state = {
    key: "",
    value: "",
    visible: false
  };

  setTooltip(key: String, value: String) {
    this.setState({ key, value });
  }

  render() {
    return (
      <div className="tooltip_kv">
        <span className="tooltip_key">{this.state.key}</span>
        <span className="tooltip_value">{this.state.value}</span>
      </div>
    );
  }
}

export default Tooltip;

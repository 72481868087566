import React from "react";
import { Button, ButtonGroup } from "reactstrap";

import Utils from "../utils/Utils";

// TODO: Support custom styling.
class ButtonRadio extends React.Component {
  props: {
    options: Array,
    defaultOptionIndex: Number,
    displayFunction: Function,
    callback: Function
  };

  state = { optionSelected: this.props.defaultOptionIndex || 0 };

  onRadioBtnClick(optionSelected: Number) {
    this.setState({ optionSelected });
    if (Utils.isFunction(this.props.callback)) {
      this.props.callback(this.props.options[optionSelected]);
    }
  }

  render() {
    if (Utils.isDataEmpty(this.props.options)) return null;

    let buttons = [];
    this.props.options.forEach((option, index) => {
      buttons.push(
        <Button
          color="warning"
          onClick={() => this.onRadioBtnClick(index)}
          active={this.state.optionSelected === index}
        >
          {Utils.isFunction(this.props.displayFunction)
            ? this.props.displayFunction(option)
            : option}
        </Button>
      );
    });

    return <ButtonGroup>{buttons}</ButtonGroup>;
  }
}

export default ButtonRadio;

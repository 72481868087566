import React from "react";

import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";

class MainLayout extends React.Component {
  props: {
    breakpoint: String
  };

  // static isSidebarOpen() {
  //   return document
  //     .querySelector(".demo-main-sidebar")
  //     .classList.contains("demo-main-sidebar--open");
  // }

  // componentWillReceiveProps({ breakpoint }) {
  //   if (breakpoint !== this.props.breakpoint) {
  //     MainLayout.checkBreakpoint(breakpoint);
  //   }
  // }

  // async componentDidMount() {
  //   MainLayout.checkBreakpoint(this.props.breakpoint);
  // }

  // static checkBreakpoint(breakpoint) {
  //   switch (breakpoint) {
  //     case "xs":
  //     case "sm":
  //     case "md":
  //       return MainLayout.openSidebar("close");
  //     case "lg":
  //     case "xl":
  //     default:
  //       return MainLayout.openSidebar("close");
  //   }
  // }

  // static openSidebar(openOrClose) {
  //   if (openOrClose === "open") {
  //     return document
  //       .querySelector(".demo-main-sidebar")
  //       .classList.add("demo-main-sidebar--open");
  //   }
  //   document
  //     .querySelector(".demo-main-sidebar")
  //     .classList.remove("demo-main-sidebar--open");
  // }

  // close sidebar when
  // handleContentClick(event) {
  //   // close sidebar if sidebar is open and screen size is less than `md`
  //   if (
  //     MainLayout.isSidebarOpen() &&
  //     (this.props.breakpoint === "xs" ||
  //       this.props.breakpoint === "sm" ||
  //       this.props.breakpoint === "md")
  //   ) {
  //     MainLayout.openSidebar("close");
  //   }
  // }

  render() {
    const { children } = this.props;
    return (
      <main className="demo-app bg-light">
        {/*<Sidebar />*/}
        {/*<Content fluid onClick={this.handleContentClick.bind(this)}>*/}
        {/*<Header />*/}
        {children}
        {/*</Content>*/}
      </main>
    );
  }
}

export default MainLayout;

import classNames from "classnames";
import React from "react";
import { Nav, Navbar } from "reactstrap";
import { Link } from "react-router-dom";

class Header extends React.Component {
  render() {
    return (
      <Navbar
        light
        expand
        className={classNames(
          "sticky-top-header",
          "header-bottom-border",
          "home-header-bg-colors"
        )}
      >
        <Nav navbar className={"header-item-margin"}>
          <Link style={{ textDecoration: "none" }} to="/">
            <h2 className={" home-header-fg-colors"}>Papergrin Demo</h2>
          </Link>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;

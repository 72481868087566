import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/css/App.css";

import LayoutRoute from "./components/LayoutRoute";
import MainLayout from "./components/MainLayout";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import TalentTrendsLayout from "./pages/TalentTrendsLayout";
// import EditorLayout from "./pages/EditorLayout";
import TechTrendsLayout from "./pages/TechTrendsLayout";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <LayoutRoute
            exact
            path="/techtrends"
            layout={MainLayout}
            component={TechTrendsLayout}
          />
          <LayoutRoute
            exact
            path="/talenttrends"
            layout={MainLayout}
            component={TalentTrendsLayout}
          />
          {/*<LayoutRoute*/}
          {/*  exact*/}
          {/*  path="/playground"*/}
          {/*  layout={MainLayout}*/}
          {/*  component={EditorLayout}*/}
          {/*/>*/}
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;

import React from "react";
import {
  MdKeyboardArrowDown,
  MdPalette,
  MdPieChart,
  MdSend,
  MdTrendingUp
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Collapse, Nav, NavItem, NavLink as BSNavLink } from "reactstrap";

import bn from "../utils/bemNames";
import Utils from "../utils/Utils";

const bem = bn.create("main-sidebar");

const navItems = [
  // { to: "/", name: "Overview", exact: true, Icon: MdPieChart },
  {
    to: [
      {
        to: "/techtrends",
        name: "Tech trends",
        exact: false,
        Icon: MdTrendingUp
      }
    ],
    name: "Demo pages",
    exact: false,
    Icon: MdSend
  }
  // { to: "/playground", name: "Playground", exact: true, Icon: MdPalette }
];

class Sidebar extends React.Component {
  state = {};

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[name] || false;
      return {
        [name]: !isOpen
      };
    });
  };

  static _renderStringItem({ to, name, exact, Icon }, index) {
    return (
      <NavItem key={index} className={bem.e("nav-item")}>
        <BSNavLink
          id={`navItem-${name}-${index}`}
          className="text-uppercase"
          tag={NavLink}
          to={to}
          activeClassName="active"
          exact={exact}
        >
          <Icon className={bem.e("nav-item-icon")} />
          <span className="">{name}</span>
        </BSNavLink>
      </NavItem>
    );
  }

  _renderObjectItem({ to, name, exact, Icon }, index) {
    return (
      <div key={index}>
        <NavItem className={bem.e("nav-item")} onClick={this.handleClick(name)}>
          <BSNavLink
            id={`navItem-${name}-${index}`}
            className={bem.e("nav-item-collapse") + " text-uppercase"}
            exact={JSON.stringify(exact)}
          >
            <div className="d-flex">
              <Icon className={bem.e("nav-item-icon")} />
              <span className=" align-self-start">{name}</span>
            </div>
            <MdKeyboardArrowDown
              className={bem.e("nav-item-icon")}
              style={{
                padding: 0,
                transform: this.state[name] ? "rotate(0deg)" : "rotate(-90deg)",
                transitionDuration: "0.3s",
                transitionProperty: "transform"
              }}
            />
          </BSNavLink>
        </NavItem>
        <Collapse
          className={"parentListItem"}
          isOpen={!Utils.isDataEmpty(this.state[name]) && this.state[name]}
        >
          {to.map((item, index) => this.itemRenderer(item, index))}
        </Collapse>
      </div>
    );
  }

  // render top level items in sidebar.
  itemRenderer = ({ to, name, exact, Icon }, index) => {
    if (typeof to === "string") {
      return Sidebar._renderStringItem({ to, name, exact, Icon }, index);
    } else if (typeof to === "object") {
      return this._renderObjectItem({ to, name, exact, Icon }, index);
    }
    return null;
  };

  render() {
    return (
      <aside className={bem.b()}>
        <div className={bem.e("background")} />
        <div className={bem.e("content")}>
          <a href="#">{/*<img src={logo} className="logo" alt="Demo" />*/}</a>
          <Nav vertical>
            {navItems.map((item, index) => this.itemRenderer(item, index))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;

import React from "react";
import { MdClearAll } from "react-icons/md";
import { withRouter } from "react-router-dom";
import { Button, Nav, Navbar } from "reactstrap";

import bn from "../utils/bemNames";

const bem = bn.create("main-header");

// Todo: Use Redux to get a dynamic name.
class Header extends React.Component {
  static handleSidebarControlButton(event: Object) {
    event.preventDefault();
    event.stopPropagation();

    document
      .querySelector(".demo-main-sidebar")
      .classList.toggle("demo-main-sidebar--open");
  }

  ////////////////////////////////////////////////
  //////////////////// RENDER ////////////////////
  ////////////////////////////////////////////////

  renderSideBarIcon() {
    return (
      <Nav navbar className="mr-2">
        <Button
          className="btn-outline-g-blue-dark"
          onClick={Header.handleSidebarControlButton.bind(this)}
        >
          <MdClearAll size="1.5em" />
        </Button>
      </Nav>
    );
  }

  render() {
    return (
      <Navbar light expand className={bem.b("bg-white")}>
        {this.renderSideBarIcon()}
      </Navbar>
    );
  }
}

export default withRouter(Header);

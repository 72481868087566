const SUPPORTED_CARTESIAN_CHARTS = new Set(["bar", "line", "horizontalBar"]);
const DEFAULT_TICK_SIZE = 24;
const CHART_PADDING_HORIZONTAL_REGULAR = "6%";
const CHART_PADDING_VERTICAL_REGULAR = "15%";
const CHART_PADDING_HORIZONTAL_DOUBLE = "12%";
const CHART_PADDING_HORIZONTAL_TRIPLE = "18%";
const DEFAULT_TITLE_POSITION = "top";

const DEFAULT_BAR_CORNER_RADIUS = 4;

export default {
  SUPPORTED_CARTESIAN_CHARTS,
  DEFAULT_TICK_SIZE,
  CHART_PADDING_HORIZONTAL_REGULAR,
  CHART_PADDING_HORIZONTAL_DOUBLE,
  CHART_PADDING_HORIZONTAL_TRIPLE,
  CHART_PADDING_VERTICAL_REGULAR,
  DEFAULT_TITLE_POSITION,
  DEFAULT_BAR_CORNER_RADIUS
};
